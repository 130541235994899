
export default {
  props: {
    programs: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        freeMode: true,
        spaceBetween: 0,
        grabCursor: true,
        slidesPerView: 'auto',
        speed: 8000,
        freeModeMomentum: true,
        autoplay: {
          delay: 1,
          disableOnInteraction: true
        },
      }
    }
  }
}
