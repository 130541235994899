import Vue from 'vue'

export default () => {
  Vue.mixin({
    methods: {
      $returnGoogleAdSenseCode(v) {
        if (!v) return false
        const obj = {}
        if (v.split('ca-pub-', 2) && v.split('ca-pub-', 2)[1] && v.split('ca-pub-', 2)[1].split('"', 1) && v.split('ca-pub-', 2)[1].split('"', 1)[0]) {
          obj.caPub = `ca-pub-${v.split('ca-pub-', 2)[1].split('"', 1)[0]}`
        }
        if (v.split('data-ad-slot="', 2) && v.split('data-ad-slot="', 2)[1] && v.split('data-ad-slot="', 2)[1].split('"', 1)[0]) {
          obj.slot = `${v.split('data-ad-slot="', 2)[1].split('"', 1)[0]}`
        }
        return  obj
      }
    }
  })
}
