
/* eslint-disable no-undef */
import VueSocialSharing from 'vue-social-sharing'
import Vue from "vue";
import GalleryModal from "../modals/GalleryModal";
import DateFormatter from "@/mixins/DateFormatter"

Vue.use(VueSocialSharing);
export default {
  components: {
    GalleryModal,
  },
  mixins: [DateFormatter],
  data() {
    return {
      baseUrl: null,
      postTitle: null,
      ogImage: null,
      pageSlug: null,
      youtubeLink: ''
    }
  },
  head() {
    return {
      script: [
        {
          src: 'https://www.youtube.com/iframe_api',
          async: true,
          defer: true,
        },
      ],
      meta: [
        {
          hid: 'og:title',
          property: 'og:title',
          name: 'og:title',
          content: this.postTitle || 'Naslov članka'
        },
        {
          hid: 'og:type',
          property: 'og:type',
          name: 'og:type',
          content: 'website'
        },
        {
          hid: 'og:image',
          property: 'og:image',
          name: 'og:image',
          content: this.ogImage || ''
        },
        {
          hid: 'og:url',
          property: 'og:url',
          name: 'og:url',
          content: `${this.baseUrl}/${this.pageSlug}`
        },
      ]
    }
  },
  mounted() {
    if (this.$refs.singlePost) {
      if (this.$refs.singlePost.pageData && this.$refs.singlePost.pageData.title) {
        this.postTitle = this.$refs.singlePost.pageData.title
      }
      if (this.$refs.singlePost.pageData && this.$refs.singlePost.pageData.media && this.$refs.singlePost.pageData.media.newPath) {
        this.ogImage = this.$refs.singlePost.pageData.media.newPath
      }
      if (this.$refs.singlePost.pageData && this.$refs.singlePost.pageData && this.$refs.singlePost.pageData.slug) {
        this.pageSlug = this.$refs.singlePost.pageData.slug
      }
    }
    if (window) {
      this.baseUrl = window.location.origin;
    }

    if (document) {
      document.getElementById('postView').addEventListener('click', (e) => {
        e.preventDefault(); // Cancel the native event
        e.stopPropagation();// Don't bubble/capture the event any further
        if (e && e.target.tagName === 'A') {
          this.youtubeLink = e.target.href || ''
        }
      })
      setTimeout(() => {
        this.setYoutubeIframe()
      }, 500)
    }

  },
  methods: {
    setYoutubeIframe() {
      let id = null
      const regex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/gm
      const textContent = document.getElementById('postView')?.textContent
      const textContentMatch = textContent.match(/(http:|https:)?\/\/(www\.)?(youtube.com|youtu.be)\/(watch)?(\?v=)?(\S+)?/)
      if (textContent && textContentMatch && textContentMatch[0]) {
        const matchUrl = textContentMatch[0]
        document.getElementById('postView').textContent = document.getElementById('postView').textContent.replace(matchUrl, '')
        id = regex.exec(matchUrl)[3]
        if (id) {
          const playerDiv = document.createElement('div')
          playerDiv.id = 'player'
          playerDiv.style.paddingTop = '30px'
          document.getElementById('postView').append(playerDiv)
          let player;
          // eslint-disable-next-line no-unused-vars,prefer-const
          player = new YT.Player('player', {
            height: '390',
            width: '100%',
            videoId: id,
            playerVars: {
              'playsinline': 1
            },
          })
        }
      }
    },
    closeModal() {
      this.youtubeLink = ''
    },
  }
}
