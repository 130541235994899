
import ControllerPostList from 'Controllers/ControllerPostList'
import ControllerPostCategoryList from 'Controllers/ControllerPostCategoryList'
import DateFormatter from "@/mixins/DateFormatter"

export default {
  components: {
    ControllerPostList,
    ControllerPostCategoryList,
  },
  mixins: [DateFormatter],
  data() {
    return {
      categories: [],
      loading: true,
    }
  },
  async mounted() {
    this.$watch(() => {
        return this.$refs.postList.status.pending
      },
      (v) => {
        if (!v) this.loading = false
      }
    )
    await this.$nextTick()
    setTimeout(() => {
      this.rearangeCategories()
    }, 400)
    setTimeout(() => {
      this.loading = false
    }, 1200)
  },
  methods: {
    rearangeCategories() {
      if (this.$refs.categoryList && this.$refs.categoryList.postCategories.length) {
        let indexOfNajgledanije = 0
        let indexOfOsijek = 0
        let indexOfRegija = 0
        let categories = [...this.$refs.categoryList.postCategories].map(el => el.slug)
        indexOfRegija = categories.indexOf('regija')
        if (indexOfRegija >= 0) {
          this.$refs.categoryList.postCategories.unshift(this.$refs.categoryList.postCategories[indexOfRegija])
          this.$refs.categoryList.postCategories.splice(indexOfRegija + 1, 1)
        }
        categories = [...this.$refs.categoryList.postCategories].map(el => el.slug)
        indexOfOsijek = categories.indexOf('osijek')
        if (indexOfOsijek >= 0) {
          this.$refs.categoryList.postCategories.unshift(this.$refs.categoryList.postCategories[indexOfOsijek])
          this.$refs.categoryList.postCategories.splice(indexOfOsijek + 1, 1)
        }
        categories = [...this.$refs.categoryList.postCategories].map(el => el.slug)
        indexOfNajgledanije = categories.indexOf('najgledanije')
        if (indexOfNajgledanije >= 0) {
          this.$refs.categoryList.postCategories.unshift(this.$refs.categoryList.postCategories[indexOfNajgledanije])
          this.$refs.categoryList.postCategories.splice(indexOfNajgledanije + 1, 1)
        }
      }
    },
    async getPosts() {
      const filters = {
        filters: [
          {key: 'postTypeSlugs', value: ['post']}
        ]
      }
      const data = (await this.$api.cms.post.filterStandard({...filters})).data
      if (data && data.data && data.data.length) {
        const posts = data.data
        posts.forEach((el) => {
          el.terms.categories.forEach((cat) => {
            this.categories.push(cat.id)
          })
        })
        this.categories = [...new Set([...this.categories])]
      }
    },
  }
}
