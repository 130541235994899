

const asyncCookieElement = () => import('../../../components/cmsOverrides/CookieElement')
const asyncErrorView = () => import('../../../components/cmsOverrides/ErrorView')
const asyncFooterElement = () => import('../../../components/cmsOverrides/FooterElement')
const asyncHeaderElement = () => import('../../../components/cmsOverrides/HeaderElement')
const asyncPostCategory = () => import('../../../components/cmsOverrides/PostCategory')
const asyncPostList = () => import('../../../components/cmsOverrides/PostList')
const asyncPostView = () => import('../../../components/cmsOverrides/PostView')
const asyncSearchView = () => import('../../../components/cmsOverrides/SearchView')
import CookieElement from '../../../components/cmsOverrides/CookieElement'
import ErrorView from '../../../components/cmsOverrides/ErrorView'
import FooterElement from '../../../components/cmsOverrides/FooterElement'
import HeaderElement from '../../../components/cmsOverrides/HeaderElement'
import PostCategory from '../../../components/cmsOverrides/PostCategory'
import PostList from '../../../components/cmsOverrides/PostList'
import PostView from '../../../components/cmsOverrides/PostView'
import SearchView from '../../../components/cmsOverrides/SearchView'

const asyncCategoryList = () => import('./shop/CategoryList')
const asyncProductList = () => import('./shop/ProductList')
const asyncCartView = () => import('./shop/CartView')
const asyncCheckoutView = () => import('./shop/CheckoutView')
const asyncCheckoutSuccessView = () => import('./shop/CheckoutSuccessView')
const asyncCheckoutFailureView = () => import('./shop/CheckoutFailureView')
const asyncProfileView = () => import('./shop/ProfileView')
const asyncLoginView = () => import('./shop/LoginView')
const asyncRegisterView = () => import('./shop/RegisterView')
const asyncMaintenanceView = () => import('./shop/MaintenanceView')
const asyncForgotPasswordView = () => import('./shop/ForgotPasswordView')
const asyncCartModalView = () => import('./shop/CartModalView')
const asyncSocialLoginView = () => import('./shop/SocialLoginView')
const asyncProductListAll = () => import('./shop/ProductListAll')
const asyncConfirmEmailView = () => import('./shop/ConfirmEmailView')
const asyncBundleCustomizationModalView = () => import('./shop/BundleCustomizationModalView')
import CategoryList from './shop/CategoryList'
import ProductList from './shop/ProductList'
import CartView from './shop/CartView'
import CheckoutView from './shop/CheckoutView'
import CheckoutSuccessView from './shop/CheckoutSuccessView'
import CheckoutFailureView from './shop/CheckoutFailureView'
import ProfileView from './shop/ProfileView'
import LoginView from './shop/LoginView'
import RegisterView from './shop/RegisterView'
import MaintenanceView from './shop/MaintenanceView'
import ForgotPasswordView from './shop/ForgotPasswordView'
import CartModalView from './shop/CartModalView'
import SocialLoginView from './shop/SocialLoginView'
import ProductListAll from './shop/ProductListAll'
import ConfirmEmailView from './shop/ConfirmEmailView'
import BundleCustomizationModalView from './shop/BundleCustomizationModalView'

export {asyncCookieElement, CookieElement, asyncErrorView, ErrorView, asyncFooterElement, FooterElement, asyncHeaderElement, HeaderElement, asyncPostCategory, PostCategory, asyncPostList, PostList, asyncPostView, PostView, asyncSearchView, SearchView, asyncCategoryList, CategoryList, asyncProductList, ProductList, asyncCartView, CartView, asyncCheckoutView, CheckoutView, asyncCheckoutSuccessView, CheckoutSuccessView, asyncCheckoutFailureView, CheckoutFailureView, asyncProfileView, ProfileView, asyncLoginView, LoginView, asyncRegisterView, RegisterView, asyncMaintenanceView, MaintenanceView, asyncForgotPasswordView, ForgotPasswordView, asyncCartModalView, CartModalView, asyncSocialLoginView, SocialLoginView, asyncProductListAll, ProductListAll, asyncConfirmEmailView, ConfirmEmailView, asyncBundleCustomizationModalView, BundleCustomizationModalView}
