import { render, staticRenderFns } from "./PostView.vue?vue&type=template&id=048f30e7&scoped=true"
import script from "./PostView.vue?vue&type=script&lang=js"
export * from "./PostView.vue?vue&type=script&lang=js"
import style0 from "./PostView.vue?vue&type=style&index=0&id=048f30e7&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "048f30e7",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerPost: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPost.js').default})
