import { render, staticRenderFns } from "./PostCategory.vue?vue&type=template&id=0e22b6b1&scoped=true"
import script from "./PostCategory.vue?vue&type=script&lang=js"
export * from "./PostCategory.vue?vue&type=script&lang=js"
import style0 from "./PostCategory.vue?vue&type=style&index=0&id=0e22b6b1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0e22b6b1",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ControllerPostCategoryList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategoryList.js').default,ControllerPostList: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostList.js').default,ControllerPostCategory: require('/home/node/app/node_modules/@gauss/cms-core/lib/components/controllers/ControllerPostCategory.js').default})
