
import ControllerError from 'Controllers/ControllerError'

export default {
  components: {
    ControllerError,
  },
  mounted() {
    setTimeout(() => {
        document.body.style.overflow = 'hidden'
        document.querySelector('.footer-wrapper').classList.add('hidden')
        document.querySelector('.header-content--bottom').classList.add(('hidden'))
    }, 1000)
  },
  beforeDestroy() {
    document.body.style.overflow = ''
    document.querySelector('.footer-wrapper').classList.remove('hidden')
    document.querySelector('.header-content--bottom').classList.remove(('hidden'))
  }
}
