
export default {
  props: {
    media: {
      type: [String, Object, Boolean],
      default: null
    },
  },
  data() {
    return {
      show: false,
      transition: true,
      oneColProductSwiper: {
        spaceBetween: 0,
        slidesPerView: 1,
        loop: true,
        autoHeight: false,
        loopedSlides: 1,
        initialSlide: 0,
      },
      // eslint-disable-next-line no-useless-escape
      reg: /(?:youtube\.com\/(?:[^\/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?\/\s]{11})/gi
    }
  },
  head() {
    if(typeof this.media !== 'string') return false
    return {
      script: [
        {
          src: 'https://www.youtube.com/iframe_api',
          async: true,
          defer: true,
        },
      ],
    }
  },
  computed: {
    checkMediaType() {
      if(this.media === '' || !this.media) {
        return  ''
      }
      if(typeof this.media === 'string') {
        return 'String'
      } else {
        return 'Other'
      }
    }
  },
  watch: {
    'media': {
      handler(v) {
        if(this.checkMediaType === 'Other') {
          this.show = !!(v && v.data && v.data.media && v.data.media.length);
        }
        if(this.checkMediaType === 'String' && v.length) {
          this.show = true
          setTimeout(() => {
            this.loadVideo(v)
          })
        }
      }
    },
    show(v) {
      if(v) {
        document.body.style.overflow = 'hidden'
        document.addEventListener('keydown', (e) => {
          if(e.which  === 27) {
            this.closeModal()
          }
        });
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  beforeDestroy() {
    this.show = false
    this.destroyVideo()
    document.removeEventListener('keydown', this.closeModal);
  },
  methods: {
    loadVideo(id) {
      if (!id) return false
      // eslint-disable-next-line
      // eslint-disable-next-line no-undef
      const videoID = id.split(this.reg)[1]
      if (!videoID) {
        alert('Dogodila se greska, provjerite jeli postavljen Youtube link!')
        this.closeModal()
        return false
      }
      // eslint-disable-next-line no-undef
      this.myPlayer = new YT.Player(this.$refs.player, {
        height: '',
        width: '',
        videoId: videoID,
        events: {
          onReady: event => {
            this.videoPlaying = true
            event.target.playVideo()
          }
        }
      })
    },
    destroyVideo() {
      if (this.myPlayer) {
        this.myPlayer.destroy()
        this.videoPlaying = false
      }
    },
    closeModal() {
      this.show = false
      this.$emit('close')
    }
  }
}
