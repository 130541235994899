import moment from "moment";

export default {
  methods: {
    $formatDate(date) {
      moment.locale(this.$application.language.code2);
      return moment(date).format("DD. MMMM YYYY");
    }
  }
};
