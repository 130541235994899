
export default {
  data() {
    return {
      offsetTop: 0,
    }
  },

  watch: {
    offsetTop(val) {
      this.callbackFunc()
    },
  },
  destroyed() {
    if (process.browser) {
      window.removeEventListener('scroll', this.onScroll)
    }
  },
  mounted() {
    if (process.browser) {
      window.addEventListener('scroll', this.onScroll)
    }
  },
  methods: {
    onScroll(e) {
      if (process.browser) {
        this.offsetTop =
          window.pageYOffset || document.documentElement.scrollTop
      }
    },
    isElementInViewport(el) {
      if (process.browser) {
        const rect = el.getBoundingClientRect()
        return (
          rect.top >= 0 &&
          rect.left >= 0 &&
          rect.bottom <=
            (window.innerHeight || document.documentElement.clientHeight) &&
          rect.right <=
            (window.innerWidth || document.documentElement.clientWidth)
        )
      }
    },
    callbackFunc() {
      const el = this.$refs.el
      if (this.isElementInViewport(el)) {
        this.$emit('inView')
      }
    },
  },
}
