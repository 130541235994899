
/* eslint-disable no-unused-vars */
import ControllerHeader from 'Controllers/ControllerHeader'
import EditorLink from 'Editors/EditorLink'
import SwiperCore, {Autoplay} from 'swiper'
import ControllerSearch from 'Controllers/ControllerSearch'
import vClickOutside from 'v-click-outside'
import transitionExpand from '@/components/transition-expand'
import HeaderNewsSlider from '@/components/sliders/header-slider/HeaderNewsSlider'

const parseString = require('xml2js').parseString;
SwiperCore.use([Autoplay]);
export default {
  components: {
    ControllerHeader,
    ControllerSearch,
    EditorLink,
    transitionExpand,
    HeaderNewsSlider
  },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      program: null,
      mobileActive: false,
      activeMobileSubmenu: null,
      search: false,
    }
  },
  computed: {
    programs() {
      if (this.program && this.program.tv && this.program.tv.programme && this.program.tv.programme.length) {
        return this.program.tv.programme.map((el) => {
          return {
            start: `${el.$.start.substring(8, 10)}:${el.$.start.substring(10, 12)}` || 0,
            title: el.title[0]._
          }
        })
      }
      return null
    }
  },
  watch: {
    search(v) {
      if (v) {
        document.body.style.overflow = 'hidden'
        setTimeout(() => {
          this.$nextTick(() => this.$refs.searchInput.focus())
        }, 200)
        this.mobileActive = false
      } else {
        document.body.style.overflow = ''
      }
    },
    $route: {
      handler(v) {
        if (v.path && v.path === '/') {
          setTimeout(() => {
            document.getElementById('cms-content').classList.remove('bg-other')
          }, 540)
        } else {
          setTimeout(() => {
            document.getElementById('cms-content').classList.add('bg-other')
          }, 540)
        }
        this.closeElements()
      },
      deep: true
    },
    mobileActive(v) {
      if (v) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = ''
      }
    }
  },
  async mounted() {
    const today = new Date();
    const date = this.str_pad(today.getDate()) + '.' + (this.str_pad(today.getMonth() + 1)) + '.' + this.str_pad(today.getFullYear())
    await fetch(`https://osjecka.gaussx.com/?date=${date}`)
      .then(response => response.text())
      .then(data => {
        // eslint-disable-next-line node/handle-callback-err
        const self = this
        parseString(data, function (err, result) {
          if (err) {
            return false
          }
          if (result) {
            self.program = result
          }
        });
      })
      .catch(console.error);
  },
  methods: {
    activateSearch(name) {
      if (name !== 'Pretraži...') {
        return false
      }
      this.$router.push(this.$route.path)
      this.search = true;
    },
    str_pad(n) {
      return String("00" + n).slice(-2);
    },
    closeElements() {
      this.search = false
      this.activeMobileSubmenu = 0
      this.mobileActive = false
      document.body.style.overflow = ''
    }
  }
}
